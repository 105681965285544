export function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken')
  if (accessToken) {
    return accessToken
  } else return ''
}

export function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem('refreshToken')
  if (refreshToken) {
    return refreshToken
  } else return ''
}

export function isTokenExpired() {
  const token = getLocalAccessToken()
  if (token) {
    const user = JSON.parse(atob(token.split('.')[1]))
    return +Date.now().toFixed() > user.exp * 1000
  } else return false
}
