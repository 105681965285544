// import { DICTIONARY_IDS } from '@/const/dictionaries.enum'

export default {
  namespaced: true,
  state: {
    isSateInit: false,
  },
  actions: {
    async initState({ rootState, state, commit, dispatch }) {
      const currentCompany = rootState.companies.currentCompany
      if (state.isSateInit && currentCompany?.id) return null
      dispatch('logs/displayOverlayLoader', true, { root: true })
      try {
        await Promise.all([
          // dispatch('companies/getAllCompaniesNoFilter', null, { root: true }),
          currentCompany
            ? dispatch('companies/loadCurrentCompany', currentCompany.id, { root: true })
            : dispatch('logs/displayWarningAlert', { message: 'Оберіть компанію' }, { root: true }),
          dispatch('profile/getUser', null, { root: true }),
        ])
        await dispatch('profile/getUserEmployers', null, { root: true })
        await dispatch('profile/setUserRoles', null, { root: true })
        await dispatch('profile/getUser', null, { root: true })
        if (rootState.profile?.user?.locale) {
          window.localStorage.setItem('currentLocale', rootState.profile.user.locale)
        }
        await dispatch('dictionaries/loadAllDictionaries', null, { root: true })
        const allDictionaries = rootState.dictionaries.allDictionaries
        const dictionaryPromises = []
        allDictionaries.forEach((dictionary) => {
          dictionaryPromises.push(dispatch('dictionaries/loadDictionary', dictionary.id, { root: true }))
        })
        await Promise.all(dictionaryPromises)
        commit('SET_STATE_IS_INITIALIZED', true)
      } catch (error) {
        console.error('Error initializing state:', error)
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
  },
  mutations: {
    SET_STATE_IS_INITIALIZED(state, val) {
      state.isSateInit = val
    },
  },
  getters: {},
}
