import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    users: [],
    usersMeta: {},
    clients: [],
    clientsMeta: {},
    initiators: [],
    initiatorsMeta: {},
    client: {},
    usersPhones: [],
    usersPhonesMeta: {},
  },
  actions: {
    async loadUsers(
      { commit, dispatch },
      { companyId, page = 1, limit = 10, sort, performer, status, search, forSearch = 0 } = {}
    ) {
      const url = forSearch ? `/api/v1/companies/${companyId}/users/forSearch` : `/api/v1/companies/${companyId}/users`
      let query = { page, limit }
      const optionalParams = { sort, performer, status, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_USERS', data.data)
        commit('SET_USERS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadClients({ commit, dispatch }, { company, page = 1, limit = 10, sort, search = '' } = {}) {
      const url = `/api/v1/companies/${company}/users/summary`
      let query = { page, limit }
      const optionalParams = { sort, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_CLIENTS', data.data)
        commit('SET_CLIENTS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadInitiators({ commit, dispatch }, { companyId, page = 1, limit = 10, sort, search, forSearch = 0 }) {
      const url = forSearch ? `/api/v1/companies/${companyId}/initiators/forSearch` : `/api/v1/companies/${companyId}/initiators`
      let query = { page, limit }
      const optionalParams = { sort, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_INITIATORS', data.data)
        commit('SET_INITIATORS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedClient({ commit, dispatch }, { companyId, userId } = {}) {
      try {
        const url = `/api/v1/companies/${companyId}/users/${userId}/summary`
        const { data } = await api.get(url)
        commit('SET_SELECTED_CLIENT', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadUsersPhones({ commit, dispatch }, companyId) {
      try {
        const { data } = await api.get(`/api/v1/companies/${companyId}/users/phones`)
        commit('SET_USERS_PHONES', data.data)
        commit('SET_USERS_PHONES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_USERS(state, data) {
      state.users = data
    },
    SET_USERS_META(state, meta) {
      state.usersMeta = meta || {}
    },
    SET_CLIENTS(state, data) {
      state.clients = data
    },
    SET_CLIENTS_META(state, meta) {
      state.clientsMeta = meta || {}
    },
    SET_INITIATORS(state, data) {
      state.initiators = data
    },
    SET_INITIATORS_META(state, meta) {
      state.initiatorsMeta = meta || {}
    },
    SET_SELECTED_CLIENT(state, data) {
      state.client = data || {}
    },
    SET_USERS_PHONES(state, data) {
      state.usersPhones = data
    },
    SET_USERS_PHONES_META(state, meta) {
      state.usersPhonesMeta = meta
    },
  },
}
