import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    positions: [],
  },
  actions: {
    async loadAllPositions({ commit, dispatch }, companyId) {
      try {
        const { data } = await api.get(`/api/v1/companies/${companyId}/positions/`)
        commit('SET_ALL_POSITIONS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ALL_POSITIONS(state, positions) {
      state.positions = positions
    },
  },
  getters: {},
}
