var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[(!_vm.isLoginPage && !_vm.isRegistrationPage && !_vm.isVerificationPage && !_vm.isRestorePasswordPage)?_c('SelectCompanyMenu',{attrs:{"allCompanies":_vm.companiesNoFilter}}):_vm._e(),(
      _vm.currentCompany &&
      _vm.currentCompany.logo &&
      !_vm.isLoginPage &&
      !_vm.isRegistrationPage &&
      !_vm.isVerificationPage &&
      !_vm.isRestorePasswordPage
    )?_c('v-avatar',{staticClass:"company-avatar mx-3",on:{"click":_vm.navigateToDashboard}},[_c('img',{attrs:{"src":`${_vm.baseURL}/${_vm.currentCompany.logo}`,"alt":"logo"}})]):_vm._e(),(
      _vm.currentCompany &&
      _vm.currentCompany.name &&
      !_vm.isLoginPage &&
      !_vm.isRegistrationPage &&
      !_vm.isVerificationPage &&
      !_vm.isRestorePasswordPage
    )?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.currentCompany.name)+" ")]):_vm._e(),_c('v-spacer'),(_vm.user)?_c('v-toolbar-title',{staticClass:"mr-2"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name))])]):_vm._e(),(!_vm.isLoginPage && !_vm.isRegistrationPage && !_vm.isVerificationPage && _vm.user)?_c('ProfileMenu',{attrs:{"avatar":_vm.user.avatar}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }