import axios from 'axios'
import router from '@/router'
// import store from '../store'
import { getLocalAccessToken, getLocalRefreshToken } from '@/helpers/getAuthToken'

const baseURL = process.env.VUE_APP_BASE_API_URL

export default class AxiosApi {
  constructor() {
    let service = axios.create({
      baseURL: baseURL,
    })
    service.interceptors.request.use(
      (config) => {
        const token = getLocalAccessToken()
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    service.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        const originalConfig = error.config
        const { status } = error.response
        if (status === 401 && !originalConfig._retry) {
          try {
            originalConfig._retry = true
            const res = await this.refreshToken()
            const { access_token, refresh_token } = res.data[0].data
            this.updateTokens(access_token, refresh_token)
            return service(originalConfig)
          } catch (e) {
            if (router.currentRoute.name !== 'Login') await this.redirectTo('Login')
          }
        } else if ([403, 404, 500].includes(status)) {
          await this.redirectTo(status.toString())
        } else if (status === 401) {
          const params = {
            errorMessage: 'Сеанс користування системою вичерпано. Будь-ласка, залогіньтесь заново!',
          }
          await this.redirectTo('Login', params)
        }
        return Promise.reject(error)
      }
    )
    this.service = service
  }

  redirectTo = async (routerName, params = {}) => {
    await router.push({
      name: routerName,
      params: params,
    })
    if (routerName === 'Login') this.clearTokens()
  }

  refreshToken() {
    return axios.post(`${baseURL}/api/v1/auth/refresh-token`, {
      refresh_token: getLocalRefreshToken(),
    })
  }

  updateTokens(access_token, refresh_token) {
    window.localStorage.setItem('accessToken', access_token)
    window.localStorage.setItem('refreshToken', refresh_token)
    this.service.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
  }

  clearTokens() {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
  }

  request = (method, url, params) => {
    const config = {
      method,
      url,
      responseType: 'json',
      ...params,
    }
    return this.service.request(config)
  }

  get = (url, params = {}) => {
    return this.request('GET', url, params)
  }

  post = (url, data = {}, params = {}) => {
    return this.request('POST', url, { data, ...params })
  }

  put = (url, data = {}, params = {}) => {
    return this.request('PUT', url, { data, ...params })
  }

  patch = (url, data = {}, params = {}) => {
    return this.request('PATCH', url, { data, ...params })
  }

  delete = (url, params = {}) => {
    return this.request('DELETE', url, params)
  }

  /*get(path) {
    return this.service.get(path)
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    })
  }

  put(path, payload) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    })
  }

  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload,
    })
  }*/
}
