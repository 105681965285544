export default {
  namespaced: true,
  state: {
    navigationMenuMini: false,
  },
  actions: {},
  mutations: {
    SET_NAVIGATION_MENU_MINI(state, val) {
      state.navigationMenuMini = val
    },
  },
  getters: {},
}
