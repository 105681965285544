import { api } from '@/const/axiosApi'

const generateAddressUrl = (payload) => {
  let url
  switch (payload.type) {
    case 'company':
      url = `/api/v1/companies/${payload.companyId}/addresses/${payload.addressId}`
      break
    case 'partner':
      url = `/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}/addresses/${payload.addressId}`
      break
    case 'servicePoint':
      url = `/api/v1/companies/${payload.companyId}/service-points/${payload.servicePointId}/addresses/${payload.addressId}`
      break
    default:
      console.error('payload.type is undefined')
  }
  return url
}

export default {
  namespaced: true,
  state: {
    address: {},
  },
  actions: {
    // TODO: replace next 'createEntityAddress' actions with 'createAddress'
    async createCompanyAddress({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.id}/address`, payload.newAddress)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createServicePointAddress({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.id}/service-points/${payload.servicePointId}/address`, payload.newAddress)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createPartnerAddress({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.id}/partner/${payload.partnerId}/address`, payload.newAddress)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    // End of 'createEntityAddress'
    async createAddress({ dispatch }, payload) {
      try {
        const url = generateAddressUrl(payload)
        await api.post(url, payload.newAddress)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAddress({ dispatch, commit }, payload) {
      try {
        const url = generateAddressUrl(payload)
        const { data } = await api.get(url)
        commit('SET_ADDRESS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateAddress({ dispatch, commit }, payload) {
      try {
        const url = generateAddressUrl(payload)
        const { data } = await api.put(url, payload.updatedAddress)
        commit('UPDATE_ADDRESS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteAddress({ dispatch }, payload) {
      try {
        const url = generateAddressUrl(payload)
        await api.delete(url)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ADDRESS(state, address) {
      state.address = address || {}
    },
    UPDATE_ADDRESS(state, address) {
      state.address = address || {}
    },
  },
  getters: {},
}
