import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    credentials: null,
    user: null,
  },
  actions: {
    async registerUser({ dispatch }, newUser) {
      try {
        await api.post('/api/v1/auth/register', newUser)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loginUser({ dispatch, commit }, payload) {
      try {
        const { data } = await api.post('/api/v1/auth/login', payload)
        commit('SET_CREDENTIALS', data[0].data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async restorePassword({ dispatch }, payload) {
      try {
        await api.post('/api/v1/auth/restore-password', payload)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async verifyCode({ dispatch }, payload) {
      try {
        await api.post('/api/v1/auth/register/verify-code', payload)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async logout({ commit, dispatch }) {
      try {
        await api.get('/api/v1/auth/logout')
        const user = null
        commit('LOGOUT', user)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_CREDENTIALS(state, credentials) {
      state.credentials = credentials
    },
    LOGOUT(state, user) {
      state.user = user
    },
  },
  getters: {},
}
