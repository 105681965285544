import { api } from '@/const/axiosApi'
import { deviceEventTypeId, terminalEventTypeId } from '@/const/constructor/eventTypes'

export default {
  namespaced: true,
  state: {
    additionalInfoArticles: [],
    additionalInfoArticle: {},
    createdAdditionalInfoArticle: {},
    additionalInfoArticlesMeta: {},
    additionalInfoCategories: [],
    additionalInfoCategoriesMeta: {},
    additionalInfoCategory: {},
    updatedAdditionalInfoCategory: {},
    additionalInfoEvents: [],
    additionalInfoEventsMeta: {},
    additionalInfoEventTypes: [],
    additionalInfoEventTypesMeta: {},
    additionalInfoEventType: {},
    additionalInfoSelectedEventType: {},
    additionalInfoEvent: {},
    terminalEvents: [],
    terminalEventsMeta: {},
    deviceEvents: [],
    deviceEventsMeta: {},
  },
  actions: {
    async loadAdditionalInfoArticles(
      { commit, dispatch },
      { page = 1, limit = 10, sort, search, status, category = '', forSearch }
    ) {
      const url = forSearch ? '/api/v1/additional-info/articles/forSearch' : '/api/v1/additional-info/articles'
      let query = { page, limit }
      const optionalParams = { sort, search, status, category }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ADDITIONAL_INFO_ARTICLES', data.data)
        commit('SET_ADDITIONAL_INFO_ARTICLES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedAdditionalInfoArticle({ commit, dispatch }, id) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/additional-info/articles/${id}`)
        commit('SET_SELECTED_ADDITIONAL_INFO_ARTICLE', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewAdditionalInfoArticle({ commit, dispatch }, newArticle) {
      try {
        const { data } = await api.post('/api/v1/additional-info/articles', newArticle)
        await dispatch('logs/displaySuccessAlert', { message: 'Статтю створено успішно!' }, { root: true })
        commit('SET_ADDITIONAL_INFO_ARTICLE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedAdditionalInfoArticle({ dispatch }, payload) {
      try {
        await api.put(`/api/v1/additional-info/articles/${payload.id}`, payload.updatedArticle)
        await dispatch('logs/displaySuccessAlert', { message: 'Статтю збережено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedAdditionalInfoArticle({ dispatch }, articleId) {
      try {
        await api.delete(`/api/v1/additional-info/articles/${articleId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Статтю видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAdditionalInfoCategories({ commit, dispatch }, { page = 1, limit = 10, sort, search = '', forSearch = 0 }) {
      const url = forSearch ? '/api/v1/additional-info/categories/forSearch' : '/api/v1/additional-info/categories'
      let query = { page, limit }
      const optionalParams = { sort, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ADDITIONAL_INFO_CATEGORIES', data.data)
        commit('SET_ADDITIONAL_INFO_CATEGORIES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedAdditionalInfoCategory({ commit, dispatch }, categoryId) {
      try {
        const { data } = await api.get(`/api/v1/additional-info/categories/${categoryId}`)
        commit('SET_SELECTED_ADDITIONAL_INFO_CATEGORY', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewAdditionalInfoCategory({ dispatch }, newCategory) {
      try {
        await api.post('/api/v1/additional-info/categories', newCategory)
        await dispatch('logs/displaySuccessAlert', { message: 'Категорію створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedAdditionalInfoCategory({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/additional-info/categories/${payload.categoryId}`, payload.updatedCategory)
        await dispatch('logs/displaySuccessAlert', { message: 'Категорію оновлено успішно!' }, { root: true })
        commit('UPDATE_SELECTED_ADDITIONAL_INFO_CATEGORY', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedAdditionalInfoCategory({ dispatch }, categoryId) {
      try {
        await api.delete(`/api/v1/additional-info/categories/${categoryId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Категорію видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAdditionalInfoEventTypes({ commit, dispatch }, { page = 1, limit = 10, sort, search = '', forSearch = 0 } = {}) {
      const url = forSearch ? '/api/v1/additional-info/event-type/forSearch' : '/api/v1/additional-info/event-type'
      let query = { page, limit }
      const optionalParams = { sort, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ADDITIONAL_INFO_EVENT_TYPES', data.data)
        commit('SET_ADDITIONAL_INFO_EVENT_TYPES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedAdditionalInfoEventType({ commit, dispatch }, eventTypeId) {
      try {
        const { data } = await api.get(`/api/v1/additional-info/event-type/${eventTypeId}`)
        commit('SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewAdditionalInfoEventType({ dispatch }, newEventType) {
      try {
        await api.post('/api/v1/additional-info/event-type', newEventType)
        await dispatch('logs/displaySuccessAlert', { message: 'Групу створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedAdditionalInfoEventType({ dispatch }, payload) {
      try {
        await api.put(`/api/v1/additional-info/event-type/${payload.eventTypeId}`, payload.updatedEventType)
        await dispatch('logs/displaySuccessAlert', { message: 'Групу оновлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedAdditionalInfoEventType({ dispatch }, eventTypeId) {
      try {
        await api.delete(`/api/v1/additional-info/event-type/${eventTypeId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Групу видалено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAdditionalInfoEventCodes(
      { commit, dispatch },
      { page = 1, limit = 10, sort, search = '', eventType = '', packetEventType = '', forSearch = 0 }
    ) {
      const url = forSearch ? '/api/v1/additional-info/event-code/forSearch' : '/api/v1/additional-info/event-code'
      let query = { page, limit }
      const optionalParams = { eventType, packetEventType, sort, search }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ADDITIONAL_INFO_EVENT_CODES', data.data)
        commit('SET_ADDITIONAL_INFO_EVENT_CODES_META', data.meta)
        switch (eventType) {
          case terminalEventTypeId: {
            commit('SET_TERMINAL_EVENT_CODES', data.data)
            commit('SET_TERMINAL_EVENT_CODES_META', data.meta)
            break
          }
          case deviceEventTypeId: {
            commit('SET_DEVICE_EVENT_CODES', data.data)
            commit('SET_DEVICE_EVENT_CODES_META', data.meta)
            break
          }
        }
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    setAllAdditionalInfoEventCodes({ commit }, payload) {
      commit('SET_ALL_ADDITIONAL_INFO_EVENT_CODES', payload)
    },
    async loadSelectedAdditionalInfoEvent({ commit, dispatch }, eventId) {
      try {
        const { data } = await api.get(`/api/v1/additional-info/event-code/${eventId}`)
        commit('SET_ADDITIONAL_INFO_EVENT', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewAdditionalInfoEvent({ commit, dispatch }, newEvent) {
      try {
        const { data } = await api.post('/api/v1/additional-info/event-code', newEvent)
        commit('CREATE_ADDITIONAL_INFO_EVENT', data.data)
        await dispatch('logs/displaySuccessAlert', { message: 'Подію створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedAdditionalInfoEvent({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/additional-info/event-code/${payload.eventId}`, payload.updatedEvent)
        commit('UPDATE_ALL_ADDITIONAL_INFO_EVENT', data.data)
        await dispatch('logs/displaySuccessAlert', { message: 'Подію оновлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedAdditionalInfoEvent({ dispatch }, eventId) {
      try {
        await api.delete(`/api/v1/additional-info/event-code/${eventId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Подію видалено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadAdditionalInfoArticleImage({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/additional-info/articles/${payload.articleId}/article-image/upload`, payload.image)
        await dispatch('logs/displaySuccessAlert', { message: 'Зображення завантажено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadAdditionalInfoArticleVideo({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/additional-info/articles/${payload.articleId}/article-video/upload`, payload.video)
        await dispatch('logs/displaySuccessAlert', { message: 'Зображення завантажено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteAdditionalInfoArticleFile({ dispatch }, payload) {
      try {
        await api.delete(`/api/v1/additional-info/articles/${payload.articleId}/file/delete?file-link=${payload.fileLink}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Файл видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ADDITIONAL_INFO_ARTICLES(state, val) {
      state.additionalInfoArticles = val
    },
    SET_SELECTED_ADDITIONAL_INFO_ARTICLE(state, val) {
      state.additionalInfoArticle = val
    },
    SET_ADDITIONAL_INFO_ARTICLE(state, val) {
      state.createdAdditionalInfoArticle = val
    },
    SET_ADDITIONAL_INFO_ARTICLES_META(state, articlesMeta) {
      state.additionalInfoArticlesMeta = articlesMeta || {}
    },
    SET_ADDITIONAL_INFO_CATEGORIES(state, val) {
      state.additionalInfoCategories = val
    },
    SET_ADDITIONAL_INFO_CATEGORIES_META(state, categoriesMeta) {
      state.additionalInfoCategoriesMeta = categoriesMeta || {}
    },
    SET_SELECTED_ADDITIONAL_INFO_CATEGORY(state, category) {
      state.additionalInfoCategory = category || {}
    },
    UPDATE_SELECTED_ADDITIONAL_INFO_CATEGORY(state, category) {
      state.updatedAdditionalInfoCategory = category || {}
    },
    SET_ADDITIONAL_INFO_EVENT_CODES_META(state, val) {
      state.additionalInfoEventsMeta = val
    },
    SET_ADDITIONAL_INFO_EVENT_CODES(state, val) {
      state.additionalInfoEvents = val
    },
    SET_TERMINAL_EVENT_CODES(state, val) {
      state.terminalEvents = val
    },
    SET_TERMINAL_EVENT_CODES_META(state, val) {
      state.terminalEventsMeta = val
    },
    SET_DEVICE_EVENT_CODES(state, val) {
      state.deviceEvents = val
    },
    SET_DEVICE_EVENT_CODES_META(state, val) {
      state.deviceEventsMeta = val
    },
    SET_ADDITIONAL_INFO_EVENT_TYPES(state, events) {
      state.additionalInfoEventTypes = events
    },
    SET_ADDITIONAL_INFO_EVENT_TYPES_META(state, eventsMeta) {
      state.additionalInfoEventTypesMeta = eventsMeta
    },
    SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE(state, eventType) {
      state.additionalInfoSelectedEventType = eventType
    },
    SET_ADDITIONAL_INFO_EVENT(state, event) {
      state.additionalInfoEvent = event
    },
    CREATE_ADDITIONAL_INFO_EVENT(state, event) {
      state.additionalInfoEvent = event
    },
    UPDATE_ALL_ADDITIONAL_INFO_EVENT(state, event) {
      state.additionalInfoEvent = event
    },
  },
  getters: {
    getAdditionalInfoArticlesTotalCount(state) {
      return state.additionalInfoArticlesMeta.total_count
    },
    getAdditionalInfoCategoriesTotalCount(state) {
      return state.additionalInfoCategoriesMeta.total_count
    },
    getAdditionalInfoEventTypesTotalCount(state) {
      return state.additionalInfoEventTypesMeta.total_count
    },
    getAdditionalInfoEventsTotalCount(state) {
      return state.additionalInfoEventsMeta.total_count
    },
    getCreatedAdditionalInfoArticleId(state) {
      return state.createdAdditionalInfoArticle.id || ''
    },
  },
}
