<template>
  <v-app-bar app clipped-left>
    <SelectCompanyMenu
      v-if="!isLoginPage && !isRegistrationPage && !isVerificationPage && !isRestorePasswordPage"
      :allCompanies="companiesNoFilter"
    ></SelectCompanyMenu>

    <v-avatar
      v-if="
        currentCompany &&
        currentCompany.logo &&
        !isLoginPage &&
        !isRegistrationPage &&
        !isVerificationPage &&
        !isRestorePasswordPage
      "
      @click="navigateToDashboard"
      class="company-avatar mx-3"
    >
      <img :src="`${baseURL}/${currentCompany.logo}`" alt="logo" />
    </v-avatar>

    <v-toolbar-title
      v-if="
        currentCompany &&
        currentCompany.name &&
        !isLoginPage &&
        !isRegistrationPage &&
        !isVerificationPage &&
        !isRestorePasswordPage
      "
    >
      {{ currentCompany.name }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-title v-if="user" class="mr-2">
      <span class="caption">{{ user.first_name }} {{ user.last_name }}</span>
    </v-toolbar-title>
    <ProfileMenu v-if="!isLoginPage && !isRegistrationPage && !isVerificationPage && user" :avatar="user.avatar"></ProfileMenu>
  </v-app-bar>
</template>

<script>
import SelectCompanyMenu from '@/components/partials/SelectCompanyMenu'
import ProfileMenu from '@/components/partials/ProfileMenu'
import { mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import defineCurrentPage from '@/mixins/defineCurrentPage'

export default {
  name: 'Header',

  components: { SelectCompanyMenu, ProfileMenu },

  mixins: [defineCurrentPage],

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    baseURL: process.env.VUE_APP_BASE_API_URL,
  }),

  computed: {
    ...mapState('companies', ['currentCompany', 'companiesNoFilter']),
    ...mapState('profile', ['user']),
  },

  methods: {
    navigateToDashboard() {
      this.$router.push(`/spa/company/${this.currentCompany.id}/${this.ROUTER_LINKS.COMPANY_DASHBOARD}`)
    },
  },
}
</script>

<style scoped lang="scss">
.company {
  &-avatar:hover {
    cursor: pointer;
  }
}
</style>
