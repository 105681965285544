import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    adminBalance: {},
    user: {},
    usersPurchase: [],
    isFileLoading: false,
  },
  actions: {
    async getAdminBalance({ commit, dispatch }) {
      try {
        const { data } = await api.get('/api/v1/admin/turbo-sms')
        commit('SET_ADMIN_BALANCE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedUser({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/admin/users/${payload.userId}`)
        commit('SET_SELECTED_USER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadUsersPurchase({ commit, dispatch }, { userId }) {
      try {
        const { data } = await api.get(`/api/v1/admin/users/${userId}/purchase-summary`)
        commit('SET_USERS_PURCHASE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewUser({ commit, dispatch }, payload) {
      try {
        const { data } = await api.post(`/api/v1/admin/users`, payload.newUser)
        await dispatch('logs/displaySuccessAlert', { message: 'Користувача створено успішно!' }, { root: true })
        commit('SET_CREATED_USER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedUser({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/admin/users/${payload.userId}`, payload.updatedUser)
        await dispatch('logs/displaySuccessAlert', { message: 'Користувача оновлено успішно!' }, { root: true })
        commit('SET_UPDATED_USER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async setUserStatus({ dispatch }, payload) {
      try {
        await api.patch(`/api/v1/admin/users/${payload.userId}/update-status?status=${payload.status}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Статус змінено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedUser({ commit, dispatch }, payload) {
      try {
        await api.delete(`/api/v1/admin/users/${payload.userId}`)
        await dispatch('logs/displayInfoAlert', { message: 'Користувача видалено!' }, { root: true })
        commit('SET_DELETED_USER')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadUserLogo({ commit, dispatch }, payload) {
      try {
        commit('SET_FILE_LOADING', true)
        await api.post(`/api/v1/admin/users/${payload.userId}/upload-avatar`, payload.formData)
        await dispatch('loadSelectedUser', { userId: payload.userId })
        await dispatch('logs/displaySuccessAlert', { message: 'Лого завантажено успішно!' }, { root: true })
        commit('SET_FILE_LOADING', false)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteUserLogo({ commit, dispatch }, payload) {
      try {
        commit('SET_FILE_LOADING', true)
        await api.delete(`/api/v1/admin/user/${payload.userId}/delete-avatar`)
        await dispatch('loadSelectedUser', { userId: payload.userId })
        await dispatch('logs/displaySuccessAlert', { message: 'Лого видалено!' }, { root: true })
        commit('SET_FILE_LOADING', false)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async restartProcessingService({ dispatch }) {
      try {
        await api.get('/api/v1/admin/packets-processing-service/restart')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ADMIN_BALANCE(state, val) {
      state.adminBalance = val
    },

    SET_SELECTED_USER(state, val) {
      state.user = val || {}
    },
    SET_USERS_PURCHASE(state, val) {
      state.usersPurchase = val || {}
    },
    SET_CREATED_USER(state, val) {
      state.user = val || {}
    },
    SET_UPDATED_USER(state, val) {
      state.user = val || {}
    },
    SET_DELETED_USER(state) {
      state.user = {}
    },
    SET_FILE_LOADING(state, val) {
      state.isFileLoading = val
    },
  },
  getters: {},
}
