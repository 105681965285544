import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    supersetGuestToken: null,
    allDashboards: [],
    allDashboardsMeta: {},
    dashboardData: null,
  },

  actions: {
    async loadSupersetGuestToken({ commit, dispatch }, payload) {
      try {
        let url = `/api/v1/companies/${payload.companyId}/dashboard/${payload.dashboardId}/guest-token`
        const params = {
          ...Object.fromEntries(Object.entries(payload.params).filter(([key, value]) => !!key && !!value)),
        }
        const { data } = await api.get(url, { params })
        commit('SET_SUPERSET_GUEST_TOKEN', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAllDashboards({ commit, dispatch }, params) {
      const url = '/api/v1/dashboard'
      const query = {
        page: params?.page || 1,
        limit: params?.limit || 10,
        ...Object.fromEntries(Object.entries(params).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: { ...query } })
        commit('SET_ALL_DASHBOARDS', data.data)
        commit('SET_ALL_DASHBOARDS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadDashboardData({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/dashboard/${payload.dashboardId}/data`)
        commit('SET_DASHBOARD_DATA', data.data)
      } catch (e) {
        commit('SET_DASHBOARD_DATA', null)
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async updateSelectedDashboard({ dispatch }, payload) {
      try {
        await api.patch(`/api/v1/dashboard/${payload.id}`, payload.updatedDashboard)
        await dispatch('logs/displaySuccessAlert', { message: 'Дашборд оновлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_SUPERSET_GUEST_TOKEN(state, val) {
      state.supersetGuestToken = val.guest_token
    },
    SET_ALL_DASHBOARDS(state, val) {
      state.allDashboards = val
    },
    SET_ALL_DASHBOARDS_META(state, val) {
      state.allDashboardsMeta = val
    },
    SET_DASHBOARD_DATA(state, val) {
      state.dashboardData = val
    },
  },

  getters: {
    getDashboardsTotalCount(state) {
      return state.allDashboardsMeta.total_count
    },
  },
}
