import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    promotions: [],
    promotion: {},
    promotionsMeta: {},
    promotionTypes: [
      { id: true, value: 'Разова' },
      { id: false, value: 'Постійна' },
    ],
    valueTypes: [
      { id: 1, value: 'грн.' },
      { id: 2, value: '%' },
    ],
    promotionParams: [
      {
        type: 6,
        title: { editable: true, required: true },
        description: { editable: true, required: true },
        users: { editable: true, required: true },
        amount: { editable: true, required: true, minValue: 1 },
      },
    ],
  },
  actions: {
    async loadPromotions(
      { commit, dispatch },
      { company, page = 1, limit = 10, sort, isAvailableOnce, type, valueType, createdAt, updatedAt, startAt, expiredAt }
    ) {
      const url = `/api/v1/companies/${company}/promotions`
      let query = { page, limit }
      const optionalParams = { sort, isAvailableOnce, type, valueType, createdAt, updatedAt, startAt, expiredAt }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const data = await api.get(url, { params: query })
        commit('SET_PROMOTIONS', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async createPromotion({ commit, dispatch }, { companyId, ...payload }) {
      const url = `/api/v1/companies/${companyId}/promotions`
      try {
        const data = await api.post(url, payload.newPromotion)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію створено успішно!' }, { root: true })
        commit('SET_PROMOTION', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async loadSelectedPromotion({ commit, dispatch }, payload) {
      const { companyId, promotionId } = payload
      const url = `/api/v1/companies/${companyId}/promotions/${promotionId}`
      try {
        const { data } = await api.get(url)
        commit('SET_PROMOTION', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async updateSelectedPromotion({ commit, dispatch }, { companyId, id, ...payload }) {
      try {
        const { data } = await api.put(`/api/v1/companies/${companyId}/promotions/${id}`, payload.updatedPromotion)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію оновлено успішно!' }, { root: true })
        commit('SET_PROMOTION', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async deleteSelectedPromotion({ dispatch }, payload) {
      const { companyId, id } = payload
      try {
        await api.delete(`/api/v1/companies/${companyId}/promotions/${id}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_PROMOTIONS(state, val) {
      state.promotions = val.data.data
      state.promotionsMeta = val.data.meta
    },

    SET_PROMOTION(state, val) {
      state.promotion = val
    },
  },

  getters: {
    getPromotionsTotalCount(state) {
      return state.promotionsMeta.total_count
    },
  },
}
