import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    encashments: [],
    encashmentsTotalCounts: {},
    encashmentsMeta: {},
    encashmentsReport: [],
    encashmentsReportMeta: {},
    encashment: {},
    prevEncashment: {},
  },
  actions: {
    async loadEncashments(
      { commit, dispatch },
      {
        companyId,
        page = 1,
        limit = 10,
        sort,
        collectors,
        balanceHolder,
        servicePoint,
        terminal,
        manualRecalculation,
        createdAt,
        updatedAt,
      } = {}
    ) {
      const url = `/api/v1/companies/${companyId}/encashments`
      let query = { page, limit }
      const optionalParams = {
        sort,
        collectors,
        balanceHolder,
        servicePoint,
        terminal,
        manualRecalculation,
        createdAt,
        updatedAt,
      }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ENCASHMENTS', data.data.encashments_data)
        commit('SET_ENCASHMENTS_TOTAL_COUNTS', data.data.encashments_total_counts)
        commit('SET_ENCASHMENTS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedEncashment({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/encashments/${payload.encashmentId}`)
        commit('SET_SELECTED_ENCASHMENT', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadPrevEncashment({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/encashments/${payload.encashmentId}`)
        commit('SET_PREV_ENCASHMENT', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadLastEncashment({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/encashments/${payload.terminalId}/get-last`)
        commit('SET_PREV_ENCASHMENT', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewEncashment({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.companyId}/encashments/`, payload.encashment)
        await dispatch('logs/displaySuccessAlert', { message: 'Інкасацію створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedEncashment({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/encashments/${payload.encashmentId}`,
          payload.encashment
        )
        commit('SET_SELECTED_ENCASHMENT', data.data)
        await dispatch('logs/displaySuccessAlert', { message: 'Інкасацію оновлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async loadReportForAllEncashments(
      { commit, dispatch },
      { companyId, page = 1, limit = 10, sort = '', direction = '', filterData } = {}
    ) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.post(
          `/api/v1/companies/${companyId}/encashments/statistics/report?page=${page}&limit=${limit}&sort=${sort}&direction=${direction}`,
          filterData
        )
        commit('SET_ENCASHMENTS_REPORT', data.data)
        commit('SET_ENCASHMENTS_REPORT_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
  },
  mutations: {
    SET_ENCASHMENTS(state, encashments) {
      state.encashments = encashments || []
    },
    SET_ENCASHMENTS_TOTAL_COUNTS(state, encashmentsTotalCounts) {
      state.encashmentsTotalCounts = encashmentsTotalCounts || {}
    },
    SET_ENCASHMENTS_META(state, encashmentsMeta) {
      state.encashmentsMeta = encashmentsMeta || {}
    },
    SET_ENCASHMENTS_REPORT(state, encashments) {
      state.encashmentsReport = encashments || {}
    },
    SET_ENCASHMENTS_REPORT_META(state, encashmentsMeta) {
      state.encashmentsReportMeta = encashmentsMeta || {}
    },
    SET_SELECTED_ENCASHMENT(state, encashment) {
      state.encashment = encashment || {}
    },
    SET_PREV_ENCASHMENT(state, prevEncashment) {
      state.prevEncashment = prevEncashment || {}
    },
  },
  getters: {
    getCompanyEncashmentsTotalCount(state) {
      return state.encashmentsMeta.total_count
    },
    getCompanyReportEncashmentsTotalCount(state) {
      return state.encashmentsReportMeta.total_count
    },
  },
}
