import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    settings: [],
    defaultSettings: [],
    settingsMeta: {},
    savedSettingsModel: null,
    isDefaultSettingsLoaded: false,
  },
  actions: {
    async loadSettings({ commit, dispatch }, { page = 1, limit = 5 } = {}) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/admin/settings?page=${page}&limit=${limit}`)
        commit('SET_SETTINGS', data.data)
        commit('SET_DEFAULT_SETTINGS', data.data)
        commit('SET_SETTINGS_META', data.meta)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSettings({ dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.put('/api/v1/admin/settings', payload)
        await dispatch('logs/displayOverlayLoader', false, { root: true })
        await dispatch('logs/displaySuccessAlert', { message: 'Налаштування оновлено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    updateSettingsModel({ commit }, payload) {
      commit('SET_SETTINGS_MODEL', payload)
    },
  },
  mutations: {
    SET_SETTINGS(state, val) {
      state.settings = val
    },
    SET_DEFAULT_SETTINGS(state, val) {
      if (!state.isDefaultSettingsLoaded) state.defaultSettings = val
      state.isDefaultSettingsLoaded = true
    },
    SET_SETTINGS_META(state, meta) {
      state.settingsMeta = meta || {}
    },
    SET_SETTINGS_MODEL(state, val) {
      state.savedSettingsModel = val
    },
  },
  getters: {
    /*getSortedSettings(state) {
      return [...state.settings].sort((a, b) => {
        const typeA = a.type.toUpperCase()
        const typeB = b.type.toUpperCase()
        return typeA.localeCompare(typeB) // Sort alphabetically
      })
    },
    getSortedSettingsByDefault(state) {
      return [...state.defaultSettings].sort((a, b) => {
        const typeA = a.type.toUpperCase()
        const typeB = b.type.toUpperCase()
        return typeA.localeCompare(typeB) // Sort alphabetically
      })
    },*/
  },
}
