import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import uk from 'vuetify/lib/locale/uk'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { uk },
    current: 'uk',
  },
  theme: {
    themes: {
      light: {
        // primary: '#3F51B5',
        // primary: '#00042B',
        primary: '#084B94',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
      },
    },
  },
})
