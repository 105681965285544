import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    companiesNoFilter: [],
    companiesNoFilterMeta: {},
    companies: [],
    companiesMeta: {},
    currentCompany: JSON.parse(window.localStorage.getItem('currentCompany')),
    company: {},
    createdCompany: {},
    filter: {},
    isFilterLoaded: false,
    liqpayWidgetRegistrationData: {},
  },

  actions: {
    async getAllCompaniesNoFilter({ commit, dispatch }, { page = 1, limit = 10, forSearch = 1 } = {}) {
      try {
        const url = `/api/v1/companies/?for-search=${forSearch}`
        const query = { page, limit }
        const { data } = await api.get(url, { params: query })
        commit('SET_ALL_COMPANIES_NO_FILTER', data.data)
        commit('SET_ALL_COMPANIES_NO_FILTER_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async getCompanies({ commit, dispatch }, { page = 1, limit = 10, sort, search = '', status, forSearch = 0 } = {}) {
      const url = forSearch ? '/api/v1/companies/forSearch' : '/api/v1/companies/'
      let query = { page, limit }
      const optionalParams = { sort, search, status }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_COMPANIES', data.data)
        commit('SET_COMPANIES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadCurrentCompany({ commit, dispatch }, id) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${id}`)
        commit('SET_CURRENT_COMPANY', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async getSelectedCompany({ commit, dispatch }, id) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${id}`)
        commit('SET_SELECTED_COMPANY', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewCompany({ commit, dispatch }, newCompany) {
      try {
        const { data } = await api.post('/api/v1/companies/', newCompany)
        await dispatch('logs/displaySuccessAlert', { message: 'Компанію створено успішно!' }, { root: true })
        commit('CREATE_NEW_COMPANY', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedCompany({ state, commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/companies/${payload.id}`, payload.updatedCompany)
        await dispatch('logs/displaySuccessAlert', { message: 'Компанію оновлено успішно!' }, { root: true })
        if (state.currentCompany.id === state.company.id) {
          commit('UPDATE_CURRENT_COMPANY', data.data)
        } else {
          commit('UPDATE_SELECTED_COMPANY', data.data)
        }
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateCompanyStatus({ state, commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/companies/${payload.id}/update-status?status=${payload.status}`)
        await dispatch(
          'logs/displaySuccessAlert',
          {
            message: `Компанію ${payload.status === 2 ? 'заблоковано' : 'розблоковано'} успішно!`,
          },
          { root: true }
        )
        if (state.currentCompany.id === state.company.id) {
          commit('UPDATE_CURRENT_COMPANY', data.data)
        } else {
          commit('UPDATE_SELECTED_COMPANY', data.data)
        }
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedCompany({ commit, dispatch }, id) {
      try {
        await api.delete(`/api/v1/companies/${id}`)
        await dispatch('logs/displayInfoAlert', { message: 'Компанію видалено!' }, { root: true })
        await dispatch('getAllCompaniesNoFilter')
        commit('DELETE_SELECTED_COMPANY')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadCompanyLogo({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.id}/upload-logo`, payload.formData)
        await dispatch('logs/displaySuccessAlert', { message: 'Лого завантажено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadFilter({ state, commit, dispatch }, id) {
      if (state.isFilterLoaded) return null
      try {
        const { data } = await api.get(`/api/v1/companies/${id}/balance-holders/filter-data`)
        commit('SET_FILTER', data.data)
        commit('SET_FILTER_LOADING_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadLiqpayWidgetRegistrationData({ commit, dispatch }, companyId) {
      try {
        const { data } = await api.get(`/api/v1/companies/${companyId}/shop-registration-data`)
        commit('SET_LIQPAY_WIDGET_REGISTRATION_DATA', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_ALL_COMPANIES_NO_FILTER(state, companies) {
      state.companiesNoFilter = companies || []
    },
    SET_ALL_COMPANIES_NO_FILTER_META(state, companiesMeta) {
      state.companiesNoFilterMeta = companiesMeta || {}
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies || []
    },
    SET_COMPANIES_META(state, companiesMeta) {
      state.companiesMeta = companiesMeta || {}
    },
    SET_CURRENT_COMPANY(state, currentCompany) {
      state.currentCompany = currentCompany
    },
    SET_SELECTED_COMPANY(state, company) {
      state.company = company || {}
    },
    CREATE_NEW_COMPANY(state, company) {
      state.createdCompany = company || {}
    },
    UPDATE_CURRENT_COMPANY(state, currentCompany) {
      state.currentCompany = currentCompany || {}
    },
    UPDATE_SELECTED_COMPANY(state, company) {
      state.company = company || {}
    },
    DELETE_SELECTED_COMPANY(state) {
      state.company = {}
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    SET_FILTER_LOADING_STATUS(state, val) {
      state.isFilterLoaded = val
    },
    SET_LIQPAY_WIDGET_REGISTRATION_DATA(state, val) {
      state.liqpayWidgetRegistrationData = val
    },
  },
  getters: {
    getCreatedCompanyId(state) {
      return state.createdCompany.id || null
    },
    getCompaniesTotalCount(state) {
      return state.companiesMeta.total_count
    },
    getUserFilter(state) {
      return state.filter.users || []
    },
    getBalanceHolderFilter(state) {
      return state.filter.balance_holders || []
    },
    getServicePointFilter(state) {
      return state.filter.service_points || []
    },
    getTerminalFilter(state) {
      return state.filter.terminals || []
    },
    getDeviceFilter(state) {
      return state.filter.devices || []
    },
  },
}
