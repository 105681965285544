import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    partners: [],
    partnersMeta: {},
    partner: {},
    createdPartner: {},
    partnership: {},
    balanceHolders: [],
    balanceHoldersMeta: {},
    servicePoints: {},
  },
  actions: {
    async loadPartners({ commit, dispatch }, { companyId, page = 1, limit = 10, sort, forSearch = 0 } = {}) {
      const url = forSearch ? `/api/v1/companies/${companyId}/partners/forSearch` : `/api/v1/companies/${companyId}/partners`
      let query = { page, limit }
      if (sort && Object.entries(sort).length) {
        query = { ...query, sort }
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_PARTNERS', data.data)
        commit('SET_PARTNERS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedPartner({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}`)
        commit('SET_SELECTED_PARTNER', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedPartnersServicePoints({ commit, dispatch }, { company, partnerId, page = 1, limit = 10, sort, status }) {
      const url = `/api/v1/companies/${company}/servicepoints/${partnerId}/rented`
      let query = { page, limit }
      const optionalParams = { sort, status }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(url, { params: query })
        commit('SET_SELECTED_PARTNERS_SERVICE_POINTS', { partnerId, points: data.data })
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewPartner({ commit, dispatch }, payload) {
      try {
        const { data } = await api.post(`/api/v1/companies/${payload.companyId}/partner`, payload.newPartner)
        await dispatch('logs/displaySuccessAlert', { message: 'Партнера створено успішно!' }, { root: true })
        commit('SET_CREATED_PARTNER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedPartner({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}`,
          payload.updatedPartner
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Партнера оновлено успішно!' }, { root: true })
        commit('SET_UPDATED_PARTNER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadBalanceHolders({ commit, dispatch }, { companyId, page = 1, limit = 10, sort, forSearch = 0 } = {}) {
      const url = forSearch
        ? `/api/v1/companies/${companyId}/balance-holders/forSearch`
        : `/api/v1/companies/${companyId}/balance-holders`
      let query = { page, limit }
      if (sort && Object.entries(sort).length) {
        query = { ...query, sort }
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_BALANCE_HOLDERS', data.data)
        commit('SET_BALANCE_HOLDERS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedPartner({ dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}`)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedPartnership({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}/partnership`)
        commit('SET_SELECTED_PARTNERSHIP', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedPartnership({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/partner/${payload.partnerId}/partnership`,
          payload.updatedPartnership
        )
        commit('SET_SELECTED_PARTNERSHIP', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadPartnerLogo({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.id}/partner/${payload.partnerId}/logo/upload`, payload.formData)
        await dispatch('logs/displaySuccessAlert', { message: 'Лого завантажено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_PARTNERS(state, partners) {
      state.partners = partners || []
    },
    SET_PARTNERS_META(state, partnersMeta) {
      state.partnersMeta = partnersMeta || {}
    },
    SET_SELECTED_PARTNER(state, partner) {
      state.partner = partner || {}
    },
    SET_SELECTED_PARTNERS_SERVICE_POINTS(state, points) {
      state.servicePoints = points || {}
    },
    SET_SELECTED_PARTNERSHIP(state, partnership) {
      state.partnership = partnership || {}
    },
    SET_CREATED_PARTNER(state, partner) {
      state.createdPartner = partner || {}
    },
    SET_UPDATED_PARTNER(state, partner) {
      state.partner = partner || {}
    },
    SET_BALANCE_HOLDERS(state, allHolders) {
      state.balanceHolders = allHolders || []
    },
    SET_BALANCE_HOLDERS_META(state, meta) {
      state.balanceHoldersMeta = meta || []
    },
  },
  getters: {
    getPartnersTotalCount(state) {
      return state.partnersMeta.total_count
    },
    getCreatedPartnerId(state) {
      return state.createdPartner.id || null
    },
  },
}
