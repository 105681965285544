import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    bonuses: [],
  },

  actions: {
    async getBonuses({ commit, dispatch }) {
      try {
        const { data } = await api.get(`/api/v1/bonus-accounts`)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async getUserBonuses({ commit, dispatch }, userId) {
      try {
        const { data } = await api.get(`/api/v1/bonus-accounts/user/${userId}`)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async addUserBonuses({ dispatch }, payload) {
      const { userId, amount, company, scope = 'local' } = payload
      try {
        await api.post(`/api/v1/companies/${company}/bonus-accounts/${userId}/${scope}`, { amount })
        dispatch('logs/displaySuccessAlert', { message: `Успішно додано ${amount} бонусів` }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_BONUSES(state, bonuses) {
      state.bonuses = bonuses
    },
  },
}
