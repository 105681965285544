export default {
  watch: {
    '$route.meta.title': {
      immediate: true,
      handler(newTitle) {
        document.title = newTitle || 'VL'
      },
    },
  },
}
