import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    terminals: [],
    terminalsMeta: {},
    terminal: {},
    connectedDeviceToTerminal: {},
  },
  actions: {
    async loadTerminals(
      { commit, dispatch },
      { company, page = 1, limit = 10, sort, search = '', status, firmware, forSearch = 0 } = {}
    ) {
      const url = forSearch ? `/api/v1/companies/${company}/terminals/forSearch` : `/api/v1/companies/${company}/terminals`
      let query = { page, limit }
      const optionalParams = { sort, search, status, firmware }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_TERMINALS', data.data)
        commit('SET_TERMINALS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedTerminal({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}`)
        commit('SET_SELECTED_TERMINAL', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewTerminal({ commit, dispatch }, payload) {
      try {
        const { data } = await api.post(`/api/v1/companies/${payload.companyId}/terminals`, payload.newTerminal)
        await dispatch('logs/displaySuccessAlert', { message: 'Термінал створено успішно!' }, { root: true })
        commit('SET_CREATED_TERMINAL', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedTerminal({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}`,
          payload.updatedTerminal
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Термінал оновлено успішно!' }, { root: true })
        commit('SET_UPDATED_TERMINAL', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedTerminal({ dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Термінал видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async connectDeviceToTerminal({ commit, dispatch }, payload) {
      try {
        await api.post(
          `/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}/add-device/${payload.deviceId}`,
          payload.connectionNumber
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Автомат підключено до терміналу!' }, { root: true })
        commit('SET_CONNECTED_DEVICE_TO_TERMINAL')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async removeDeviceFromTerminal({ dispatch }, payload) {
      try {
        await api.delete(
          `/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}/remove-device/${payload.deviceId}`
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Автомат відключено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async sendPackage({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.companyId}/terminals/${payload.terminalId}/send-packet`, payload.data)
        await dispatch('logs/displaySuccessAlert', { message: 'Пакет відправлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_TERMINALS(state, val) {
      state.terminals = val
    },
    SET_TERMINALS_META(state, companiesMeta) {
      state.terminalsMeta = companiesMeta || {}
    },
    SET_SELECTED_TERMINAL(state, terminal) {
      state.terminal = terminal || {}
    },
    SET_CREATED_TERMINAL(state, terminal) {
      // state.terminal = terminal || {}
      console.log(state.terminal)
      console.log(terminal)
    },
    SET_UPDATED_TERMINAL(state, terminal) {
      state.terminal = terminal || {}
    },
    SET_CONNECTED_DEVICE_TO_TERMINAL(state, connectedDevice) {
      state.connectedDeviceToTerminal = connectedDevice || {}
    },
  },
  getters: {
    getTerminalsTotalCount(state) {
      return state.terminalsMeta.total_count
    },
  },
}
