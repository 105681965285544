import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    devicePriceList: null,
    devicePriceListMeta: {},
    allPriceLists: null,
    newPriceList: null,
  },
  actions: {
    async loadDevicePriceList({ commit, dispatch }, payload) {
      try {
        // dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(
          `/api/v1/companies/${payload.companyId}/price-list/device/${payload.deviceType}/terminal/${payload.terminalId}`
        )
        commit('SET_DEVICE_PRICE_LIST', data.data)
        commit('SET_DEVICE_PRICE_LIST_META', data.meta)
        // dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        commit('SET_DEVICE_PRICE_LIST', {})
        dispatch('logs/displayErrorAlert', e.response, { root: true })
        // dispatch('logs/displayOverlayLoader', false, { root: true })
        throw new Error(e)
      }
    },
    async loadAllPriceLists({ commit, dispatch }, companyId) {
      try {
        const { data } = await api.get(`/api/v1/companies/${companyId}/price-list`)
        commit('SET_ALL_PRICE_LISTS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadNewPriceList({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.post(
          `/api/v1/companies/${payload.companyId}/price-list/compare-and-get/${payload.selectedPriceListId}`,
          payload.devicePriceList
        )
        commit('SET_NEW_PRICE_LIST', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        commit('SET_NEW_PRICE_LIST', null)
        dispatch('logs/displayErrorAlert', e.response, { root: true })
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async saveNewPriceList({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.post(`/api/v1/companies/${payload.companyId}/price-list`, payload)
        await dispatch('logs/displaySuccessAlert', { message: 'Ціну оновлено успішно!' }, { root: true })
        commit('SET_NEW_PRICE_LIST', data.data)
        await dispatch('loadAllPriceLists', payload.companyId)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async savePriceListToDevice({ dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.post(`/api/v1/companies/${payload.companyId}/price-list/update/${payload.id}/terminal/${payload.terminalId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Прайслист відправлено на автомат!' }, { root: true })
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
        dispatch('logs/displayOverlayLoader', false, { root: true })
        throw new Error(e)
      }
    },
    async deletePriceList({ dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.delete(`/api/v1/companies/${payload.companyId}/price-list/${payload.selectedPriceListId}`)
        await dispatch('logs/displaySuccessAlert', { message: 'Прайслист видалено!' }, { root: true })
        await dispatch('loadAllPriceLists', payload.companyId)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
  },
  mutations: {
    SET_DEVICE_PRICE_LIST(state, devicePriceList) {
      state.devicePriceList = devicePriceList
    },
    SET_DEVICE_PRICE_LIST_META(state, meta) {
      state.devicePriceListMeta = meta
    },
    SET_ALL_PRICE_LISTS(state, allPriceLists) {
      state.allPriceLists = allPriceLists
    },
    SET_NEW_PRICE_LIST(state, newPriceList) {
      state.newPriceList = newPriceList || null
    },
  },
  getters: {},
}
