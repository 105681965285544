import Vue from 'vue'
import Vuex from 'vuex'

import init from './modules/init'
import auth from './modules/auth'
import admin from './modules/admin'
import addresses from './modules/addresses'
import companies from './modules/companies'
import partners from './modules/partners'
import staff from './modules/staff'
import terminals from './modules/terminals'
import devices from './modules/devices'
import servicePoints from './modules/servicePoints'
import profile from './modules/profile'
import positions from './modules/positions'
import monitoring from './modules/monitoring'
import payments from './modules/payments'
import encashments from './modules/encashments'
import infoConstructor from './modules/infoConstructor'
import logs from './modules/logs'
import dictionaries from './modules/dictionaries'
import settings from './modules/settings'
import common from './modules/common'
import firmwares from './modules/firmwares'
import users from './modules/users'
import priceList from './modules/priceList'
import bonuses from '@/store/modules/bonuses'
import promotions from '@/store/modules/promotions'
import qr from '@/store/modules/qr'
import pushNotification from '@/store/modules/pushNotification'
import dashboard from '@/store/modules/dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    init,
    auth,
    admin,
    addresses,
    companies,
    partners,
    staff,
    terminals,
    devices,
    servicePoints,
    profile,
    positions,
    monitoring,
    payments,
    encashments,
    infoConstructor,
    logs,
    dictionaries,
    settings,
    common,
    firmwares,
    users,
    priceList,
    bonuses,
    promotions,
    qr,
    pushNotification,
    dashboard,
  },
})
