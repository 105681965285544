import { api } from '@/const/axiosApi'

const billAcceptor = JSON.parse(window.localStorage.getItem('billAcceptor'))
const posTerminal = JSON.parse(window.localStorage.getItem('posTerminal'))

export default {
  namespaced: true,
  state: {
    monitoringList: [],
    monitoringListMeta: {},
    showBillAcceptor: billAcceptor !== null ? billAcceptor.isShow : true,
    showPosTerminal: posTerminal !== null ? posTerminal.isShow : true,
  },
  actions: {
    async loadMonitoringData({ commit, dispatch }, { companyId, page, limit, search, sort }) {
      try {
        const url = `/api/v1/companies/${companyId}/monitoring/data`
        const params = { page, limit, search, sort }
        const { data } = await api.get(url, { params })
        commit('SET_MONITORING_DATA', data.data)
        commit('SET_MONITORING_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async sendMonitoringTerminalCommand({ dispatch }, payload) {
      try {
        await api.post(
          `/api/v1/companies/${payload.companyId}/monitoring/terminals/${payload.terminalId}/directive/${payload.directiveTypeId}/send`
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Команду відправлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async sendMonitoringDeviceCommand({ dispatch }, payload) {
      try {
        await api.post(
          `/api/v1/companies/${payload.companyId}/monitoring/terminals/${payload.terminalId}/devices/${payload.deviceId}/directive/${payload.directiveTypeId}/send`
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Команду відправлено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_MONITORING_DATA(state, val) {
      state.monitoringList = val
    },
    SET_MONITORING_META(state, val) {
      state.monitoringListMeta = val
    },
    TOGGLE_BILL_ACCEPTOR(state, val) {
      state.showBillAcceptor = val
    },
    TOGGLE_POS_TERMINAL(state, val) {
      state.showPosTerminal = val
    },
  },
  getters: {
    getMonitoringListTotalPages(state) {
      return state.monitoringListMeta.total_pages
    },
    getMonitoringListTotalCount(state) {
      return state.monitoringListMeta.total_count
    },
  },
}
