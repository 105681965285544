import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import DatetimePicker from 'vuetify-datetime-picker'
import VueHtmlToPaper from 'vue-html-to-paper'
import CapitalizeLetter from './plugins/capitalizeLetter'
import directives from '@/directives'
import GetUserRoles from '@/mixins/getUserRoles'
import './assets/scss/index.scss'
import { CURRENT_LOCALE, DEFAULT_LOCALE } from '@/const/globals'
// import axios from 'axios'

/** ***  add global variables *** **/
// import { CURRENT_COMPANY } from './const/globals'
// Vue.prototype.$currentCompany = CURRENT_COMPANY
/** ***  end *** **/
Vue.prototype.$isProductionMode = process.env.NODE_ENV.toLowerCase() === 'production'
Vue.prototype.$currentLocale = CURRENT_LOCALE
Vue.prototype.$defaultLocale = DEFAULT_LOCALE

Vue.use(VueTheMask)
Vue.use(DatetimePicker)
Vue.use(VueHtmlToPaper)
Vue.use(CapitalizeLetter)
Vue.mixin(GetUserRoles)

directives.forEach((item) => {
  Vue.directive(item.name, item.directive)
})

Vue.config.productionTip = false
// axios.defaults.withCredentials = true
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
