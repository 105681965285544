import { Centrifuge } from 'centrifuge'
import { api } from '@/const/axiosApi'

const baseWsURL = process.env.VUE_APP_BASE_WS_URL

export default class CentrifugeApi {
  constructor() {
    this.centrifuge = new Centrifuge(baseWsURL, { debug: true })
  }

  async connectToCentrifuge() {
    const { data } = await api.get('/api/v1/centrifuge/get-user-token')
    const token = data.data.token
    this.centrifuge.setToken(token)
    /*this.centrifuge.on('connected', function (ctx) {
      console.log('connected', ctx)
    })*/
    this.centrifuge.on('disconnected', function (ctx) {
      console.log('disconnected', ctx)
    })
    this.centrifuge.on('error', function (ctx) {
      console.error(ctx)
    })
    this.centrifuge.connect()
  }

  disconnectFromCentrifuge() {
    this.centrifuge.disconnect()
  }
}
