const ADMIN_TITLE = 'Адмін'
const COMPANY_OWNER_TITLE = 'Власник'
const COMPANY_ACCOUNTANT_TITLE = 'Бухгалтер'
const COMPANY_TECHNICIAN_TITLE = 'Технік'
const SYSTEM_TECHNICAL_SUPPORT_TITLE = 'Техпідтримка'
const ROLE_USER_TITLE = 'Користувач'

export const ROLES = Object.freeze([
  {
    title: ADMIN_TITLE,
    type: 'ROLE_ADMIN',
  },
  {
    title: COMPANY_OWNER_TITLE,
    type: 'ROLE_COMPANY_OWNER',
  },
  {
    title: COMPANY_ACCOUNTANT_TITLE,
    type: 'ROLE_ACCOUNTANT',
  },
  {
    title: COMPANY_TECHNICIAN_TITLE,
    type: 'ROLE_COMPANY_TECHNICIAN',
  },
  {
    title: SYSTEM_TECHNICAL_SUPPORT_TITLE,
    type: 'ROLE_SYSTEM_TECHNICAL_SUPPORT',
  },
  {
    title: ROLE_USER_TITLE,
    type: 'ROLE_USER',
  },
])

export const ROLE = Object.freeze({
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  OWNER: 'ROLE_COMPANY_OWNER',
  ACCOUNTANT: 'ROLE_ACCOUNTANT',
  TECHNICIAN: 'ROLE_COMPANY_TECHNICIAN',
  SUPPORT: 'ROLE_SYSTEM_TECHNICAL_SUPPORT',
})
