import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    file: null,
  },
  actions: {
    async loadQrCode({ commit, dispatch }, payload) {
      try {
        const url = `/api/v1/companies/${payload.companyId}/qr-code/generate`
        const params = { data: payload.data }
        const data = await api.get(url, { params })
        console.log(data.headers)
        commit('SET_QR_CODE_FILE', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_QR_CODE_FILE(state, val) {
      // console.log(val)
      state.file = val
    },
  },
  getters: {},
}
