export default {
  bind(el, binding) {
    const { value = '' } = binding
    el.addEventListener('mouseenter', () => {
      el.classList.add(value)
    })
    el.addEventListener('mouseleave', () => {
      el.classList.remove(value)
    })
  },
  unbind(el, binding) {
    const { value = '' } = binding
    el.removeEventListener('mouseenter', () => {
      el.classList.add(value)
    })
    el.removeEventListener('mouseleave', () => {
      el.classList.remove(value)
    })
  },
}
