export default {
  computed: {
    isLoginPage() {
      if (this.$route.name) return this.$route.name.toLowerCase() === 'login'
    },
    isRegistrationPage() {
      if (this.$route.name) return this.$route.name.toLowerCase() === 'registration'
    },
    isVerificationPage() {
      if (this.$route.name) return this.$route.name.toLowerCase() === 'verification'
    },
    isRestorePasswordPage() {
      if (this.$route.name) return this.$route.name.toLowerCase() === 'restorepassword'
    },
  },
}
