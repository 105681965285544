<template>
  <v-footer class="" padless app fixed outlined height="50">
    <v-card flat tile width="100%" class="text-center" light color="transparent">
      <div class="d-flex justify-center align-center">
        <v-img :src="require('../assets/cloud-mate-logo.svg')" max-width="80" class="mr-5" />
        <span class="font-weight-medium mt-2">CloudMate © {{ new Date().getFullYear() }}</span>
      </div>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
  }),
}
</script>

<style lang="scss"></style>
