import { api } from '@/const/axiosApi'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    currentNotificationSettings: {},
    notificationSettings: [],
    notificationSettingsMeta: {},
    notificationLogsAuto: [],
    notificationLogsAutoMeta: {},
    notificationLogsInst: [],
    notificationLogsInstMeta: {},
    currentLocale: '',
  },
  actions: {
    async createPushNotifications({ dispatch }, payload) {
      try {
        await dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.post('/api/v1/admin/push-notifications/settings', payload)
        await dispatch('logs/displayOverlayLoader', false, { root: true })
        await dispatch('logs/displaySuccessAlert', { message: 'Пуш-повідомлення відправлено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async updatePushNotifications({ dispatch }, payload) {
      try {
        await dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.put(`/api/v1/admin/push-notifications/settings/${payload.id}`, payload.data)
        await dispatch('logs/displayOverlayLoader', false, { root: true })
        await dispatch('logs/displaySuccessAlert', { message: 'Пуш-повідомлення оновлено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async loadNotificationSettings(
      { commit, dispatch },
      { page = 1, limit = 10, isOneTime = 0, sort, event, initiator, type, createdAt, updatedAt, startAt, expiredAt, ...rest }
    ) {
      try {
        const url = '/api/v1/admin/push-notifications/settings'
        let query = { page, limit, isOneTime }
        const optionalParams = { sort, event, initiator, type, createdAt, updatedAt, startAt, expiredAt, ...rest }
        query = {
          ...query,
          ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
        }
        const { data } = await api.get(url, { params: query })
        commit('SET_NOTIFICATION_SETTINGS', data.data)
        commit('SET_NOTIFICATION_SETTINGS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadCurrentPushNotificationSettings({ commit, dispatch }, notificationId) {
      try {
        await dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/admin/push-notifications/settings/${notificationId}`)
        commit('SET_CURRENT_NOTIFICATION_SETTINGS', data.data)
        await dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async loadPushNotificationsLogs({ commit, dispatch }, { page = 1, limit = 10, ...rest } = {}) {
      const url = '/api/v1/admin/push-notifications/logs'
      let query = { page, limit }
      const { logsType } = rest
      delete rest.logsType
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(rest).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        if (logsType) {
          commit('SET_NOTIFICATION_LOGS_INST', data.data)
          commit('SET_NOTIFICATION_LOGS_INST_META', data.meta)
        } else {
          commit('SET_NOTIFICATION_LOGS_AUTO', data.data)
          commit('SET_NOTIFICATION_LOGS_AUTO_META', data.meta)
        }
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deletePushNotification({ dispatch }, payload) {
      try {
        await dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.delete(`/api/v1/admin/push-notifications/settings/${payload.id}`)
        await dispatch('logs/displayOverlayLoader', false, { root: true })
        await dispatch('logs/displaySuccessAlert', { message: 'Пуш-повідомлення видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
  },
  mutations: {
    SET_NOTIFICATION_SETTINGS(state, data) {
      state.notificationSettings = data
    },
    SET_NOTIFICATION_SETTINGS_META(state, data) {
      state.notificationSettingsMeta = data
    },
    SET_NOTIFICATION_LOGS_AUTO(state, data) {
      state.notificationLogsAuto = data
    },
    SET_NOTIFICATION_LOGS_AUTO_META(state, data) {
      state.notificationLogsAutoMeta = data
    },
    SET_NOTIFICATION_LOGS_INST(state, data) {
      state.notificationLogsInst = data
    },
    SET_NOTIFICATION_LOGS_INST_META(state, data) {
      state.notificationLogsInstMeta = data
    },

    SET_CURRENT_NOTIFICATION_SETTINGS(state, data) {
      state.currentNotificationSettings = data
    },
    SET_CURRENT_PUSH_LOCALE(state, data) {
      state.currentLocale = data
    },
  },
  getters: {
    getNotificationSettingsCount(state) {
      return state.notificationSettingsMeta.total_count
    },
    getAvailableNotificationLanguages(state) {
      return Object.keys(state.currentNotificationSettings.content) || []
    },
    getNotificationLogsAutoCount(state) {
      return state.notificationLogsAutoMeta.total_count
    },
    getNotificationLogsInstCount(state) {
      return state.notificationLogsInstMeta.total_count
    },
    getModifiedSetting1(state) {
      return [
        {
          title: 'ID',
          value: state.currentNotificationSettings.id,
        },
        {
          title: 'Назва',
          value: state.currentNotificationSettings.content[state.currentLocale].title,
        },
        {
          title: 'Текст',
          value: state.currentNotificationSettings.content[state.currentLocale].body,
        },
        {
          title: 'Створено',
          value: moment(state.currentNotificationSettings.created_at).format('DD.MM.YYYY HH:mm:ss') || 'N/A',
        },
        {
          title: 'Змінено',
          value: moment(state.currentNotificationSettings.updated_at).format('DD.MM.YYYY HH:mm:ss') || 'N/A',
        },
        {
          title: 'Відправлено',
          value: moment(state.currentNotificationSettings.start_at).format('DD.MM.YYYY HH:mm:ss') || 'N/A',
        },
        {
          title: 'Дійсний до',
          value: moment(state.currentNotificationSettings.expired_at).format('DD.MM.YYYY HH:mm:ss') || 'N/A',
        },
        {
          title: 'Ініціатор',
          value:
            state.currentNotificationSettings.initiator.first_name + ' ' + state.currentNotificationSettings.initiator.last_name,
        },
      ]
    },
    getModifiedSetting2(state, getters, rootState) {
      const eventDictionary = rootState['dictionaries'].pushNotificationEventsDictionary
      const typeDictionary = rootState['dictionaries'].pushNotificationTypesDictionary
      const receiverTypeDictionary = rootState['dictionaries'].pushNotificationRecipientsDictionary
      const deviceTypeDictionary = rootState['dictionaries'].machinesTypesDictionary
      return [
        {
          title: 'Подія',
          value: getItemTitle(eventDictionary, state.currentNotificationSettings.event),
        },
        {
          title: 'Тип',
          value: getItemTitle(typeDictionary, state.currentNotificationSettings.type),
        },
        {
          title: 'Зберегти клієнту',
          value: state.currentNotificationSettings.is_save ? 'Так' : 'Ні',
        },
        {
          title: 'Отримувачі',
          value: getItemTitle(receiverTypeDictionary, state.currentNotificationSettings.receiver_type),
        },
        {
          title: 'Компанія',
          value: mapAndJoin(state.currentNotificationSettings.companies, 'name'),
        },
        {
          title: 'Орендодавець',
          value: mapAndJoin(state.currentNotificationSettings.balance_holders, 'name'),
        },
        {
          title: 'Торгівельна точка ',
          value: mapAndJoin(state.currentNotificationSettings.service_points, 'name', (item) => {
            const address = item.address
            return `${item.name} (${address.city}, ${address.street} ${address.building_number})`
          }),
        },
        {
          title: 'Автомат ',
          value: mapAndJoin(state.currentNotificationSettings.devices, 'id', (item) => {
            const type = getItemTitle(deviceTypeDictionary, item.type)
            return `${type}${item.id}`
          }),
        },
      ]
    },
  },
}

function mapAndJoin(array, key, formatter = (item) => item[key]) {
  if (key === 'id') {
    return array ? array.map(formatter).join(', ') : 'N/A'
  } else return array ? array.map(formatter).join(', ') : 'N/A'
}

function getItemTitle(dictionary, val) {
  return dictionary.find((item) => item.value === val)?.name || val
}
